export default {
  map: {
    "Your Location": "Ihr Standort",
    Satellite: "Satellit",
    "Make a sections": "Abschnitte erstellen",
    "Make a section": "Abschnitte erstellen",
    Line: "Strecke",
    "Fit to country": "Passend zum Land",
    Finish: "Beenden",
    "Delete section": "Abschnitt löschen",
    Add: "Hinzufügen",
    Edit: "Bearbeiten",
    "Make Report": "Bericht erstellen",
    Cancel: "Stornieren",
    "Add Section": "Abschnitt hinzufügen",
    "Edit Section": "Abschnitt bearbeiten",
    "Section name": "Abschnittsname",
    State: "Zustand",
    Railway: "Eisenbahn",
    Country: "Land",
    Station: "Bahnhof",
    Company: "Unternehmen",
    "Section Type": "Abschnittstyp",
    "Sections Types": "Abschnittstypen",
    Copied: "Kopiert",
    Copy: "Kopieren",
    "Make an abnormality": "Machen Sie eine Anomalie",
    "Your current location": "Ihr aktueller Standort",
    "Zoom in": "Vergrößern",
    "Zoom out": "Herauszoomen",
    "Contact wire height overrun": "Überschreitung der Fahrdrahthöhe",
    "Contact wire zig-zag overrun": "Fahrdraht im Zickzack überfahren",
    "Occurrence of Auflauf": "Vorkommen von Auflauf",
    "Contact wire wearing": "Fahrdrahtverschleiß",
    "Occurrence of arc on carbon strip":
      "Auftreten eines Lichtbogens auf einem Kohlenstoffstreifen",
    "Contact force overrun": "Kontaktkraftüberschreitung",
    "Occurrence of impact signal": "Auftreten des Aufprallsignals",
    Unavailable: "Nicht verfügbar",
    error: "Fehler",
    "Something went wrong": "Etwas ist schiefgelaufen",
    "The image could not be imported.":
      "Das Bild konnte nicht importiert werden.",
    "Your account doesn't assign to any device":
      "Ihr Konto ist keinem Gerät zugeordnet.",
    success: "Erfolg",
    "Abnormality added": "Abnormalität hinzugefügt",
    "The abnormality added successfully.":
      "Die Abnormalität wurde erfolgreich hinzugefügt.",
    "If you sort abnormality by time you can find easily this abnormality.":
      "Wenn Sie die Abnormalität nach Zeit sortieren, können Sie diese Abnormalität leicht finden.",
    warning: "Warnung",
    "you didn't enter value.": "Sie haben keinen Wert eingegeben.",
    "The abnormality added successfully":
      "Die Abnormalität wurde erfolgreich hinzugefügt.",
    "The abnormality could not be added.":
      "Die Abnormalität konnte nicht hinzugefügt werden.",
    "Make abnormality": "Abnormalität erstellen",
    "No need to repair": "Kein Reparaturbedarf",
    "Need to repair": "Reparatur erforderlich",
    Repaired: "Repariert",
    "Scratched C.W": "Kratzer C.W.",
    "Dropper rupture": "Abreißdroper",
    "Dropper drop": "Abfallende Abreißdroper",
    "Overhead drop": "Überkopfleitungsabfall",
    Tension: "Spannung",
    "Wire wearing": "Drahtabnutzung",
    "Wire height overrun": "Drahthöhe überschritten",
    "Wire zig-zag overrun": "Draht-Zickzack-Überlauf",
    Arc: "Bogen",
    Auflauf: "Auflauf",
    Other: "Sonstiges",
    "point status": "Punktstatus",
    "fault category": "Fehlerkategorie",
    "Please set location access allowed":
      "Bitte erlauben Sie den Standortzugriff",
    "The section could not be added":
      "Der Abschnitt konnte nicht hinzugefügt werden.",
    "Section added": "Abschnitt hinzugefügt",
    "The section added successfully.":
      "Der Abschnitt wurde erfolgreich hinzugefügt.",
    "Section edited": "Abschnitt bearbeitet",
    "The section edited successfully.":
      "Der Abschnitt wurde erfolgreich bearbeitet.",
    "The section could not be removed.":
      "Der Abschnitt konnte nicht entfernt werden.",
    "Section removed": "Abschnitt entfernt",
    "The section removed successfully.":
      "Der Abschnitt wurde erfolgreich entfernt.",
    Description: "Beschreibung",
    "The section could not be added.":
      "Der Abschnitt konnte nicht hinzugefügt werden.",
    "No points found :(": "Keine Punkte gefunden :(",
  },
};
