export default {
  components: {
    "Start typing...": "Beginnen Sie zu tippen...",
    "Map not loaded correctly. An unknown error occurred.":
      "Karte wurde nicht richtig geladen. Ein unbekannter Fehler ist aufgetreten.",
    "Error loading maps": "Fehler beim Laden der Karten",
    "Loading...": "Laden...",
    "An error occurred in the map section. Our team has been notified.":
      "Ein Fehler ist im Kartenbereich aufgetreten. Unser Team wurde benachrichtigt.",
    "Fetching address...": "Adresse wird abgerufen...",
    "Address not available": "Adresse nicht verfügbar",
    "Address information could not be retrieved from the backend.":
      "Adressinformationen konnten nicht vom Backend abgerufen werden.",
    "Error fetching address. Please check the backend configuration.":
      "Fehler beim Abrufen der Adresse. Bitte überprüfen Sie die Backend-Konfiguration.",
    "No address available": "Keine Adresse verfügbar",
    "No valid markers found.": "Keine gültigen Marker gefunden.",
    "An unknown error occurred.": "Ein unbekannter Fehler ist aufgetreten.",
    "Map not loaded correctly.": "Karte wurde nicht richtig geladen.",
    "No points found :(": "Keine Punkte gefunden :(",
    "Make an abnormality": "Eine Abnormalität erstellen",
    "Your current location": "Ihr aktueller Standort",
    "Zoom in": "Vergrößern",
    "Zoom out": "Herauszoomen",
    "Color Legend": "Farbenlegende",
    "Selected abnormality": "Ausgewählte Abnormalität",
    "Abnormalities from the same signal": "Abnormalitäten vom gleichen Signal",
    "Abnormalities from the same point": "Abnormalitäten vom gleichen Punkt",
    "select item...": "Artikel auswählen...",
  },
};
